import Swal from 'sweetalert2'
let FileSaver = require('file-saver')
export default {
  methods: {
    YesNoAlert: function (settings) {
      let defaultSettings = {
        type: 'warning',
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'Nie',
        confirmButtonText: 'Áno'
      }
      return Swal.fire(Object.assign(defaultSettings, settings))
    },
    PopupAlert (settings) {
      let defaultSettings = {
        position: 'top-end',
        type: 'success',
        showConfirmButton: false,
        timer: 1600
      }
      Swal.fire(Object.assign(defaultSettings, settings))
    },
    ShowLoading (settings) {
      let defaultSettings = {
        onBeforeOpen: () => {
          Swal.showLoading()
        }
      }
      Swal.fire(Object.assign(defaultSettings, settings))
    },
    CloseLoading () {
      Swal.close()
    },
    BasicAlert: function (settings) {
      return Swal.fire(settings)
    },
    OkAlert: function (settings) {
      let defaultSettings = {
        type: 'success'
      }
      return this.BasicAlert(Object.assign(defaultSettings, settings))
    },
    ErrorAlert: function (settings, e) {
      console.log(e)
      if (e && e.display) {
        const defaultSettings = {
          type: 'error',
          title: 'Chyba',
          text: e.display
        }
        return this.BasicAlert(defaultSettings)
      } else {
        const defaultSettings = {
          type: 'error'
        }
        return this.BasicAlert(Object.assign(defaultSettings, settings))
      }
    },
    ErrorAlertReport: function (settings, exception) {
      let defaultSettings = {
        type: 'error',
        confirmButtonText: 'Nie',
        cancelButtonText: 'Odoslať',
        showCancelButton: true,
        title: 'Nastala chyba',
        text: 'Chcete odoslať hlásenie ?'
      }
      let blob = new Blob([JSON.stringify(exception)], { type: 'text/plain;charset=utf-8' })
      this.YesNoAlert(Object.assign(defaultSettings, settings)).then((result) => { if (!result.value) { FileSaver.saveAs(blob, 'report.log') } })
    },
    InfoAlert: function (settings) {
      let defaultSettings = {
        type: 'info'
      }
      return this.BasicAlert(Object.assign(defaultSettings, settings))
    }
  }
}
