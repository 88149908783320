import axios from 'axios'
import router from '@/router'
import STORE from '@/store'

const TenderiaHttp = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_HOST,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

const token = localStorage.getItem('token')

if (token) {
  TenderiaHttp.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

TenderiaHttp.interceptors.request.use(function (config) {
  config.url = process.env.VUE_APP_BACKEND_URL + config.url
  return config
}, function (error) {
  return Promise.reject(error)
})

TenderiaHttp.interceptors.response.use(function (response) {
  STORE.commit('setCurrentTime', parseInt(response.headers['x-server-time']))
  return response
}, function (error) {
  if (error?.response?.status === 401 && localStorage.getItem('token')) {
    localStorage.clear()
    router.push({ path: '/login' })
  }
  return Promise.reject(error)
})

export { TenderiaHttp }
