<template>
  <div class="layout">
    <app-login-header />
    <app-sub-header
      :back-link="backLink"
      :organization-name="organizationName"
    />
    <!-- Page Container START -->
    <div class="page-container">
      <registration-banner class="pdd-top-15"/>
      <!-- Content Wrapper START -->
      <div class="main-content">
        <div class="container-fluid">
          <div v-if="organization" class="row mt-3">
            <div class="col-md-6">
              <div class="card">
                <div class="card-heading">
                  <h4 class="card-title">Verejný obstarávateľ</h4>
                </div>
                <div class="card-block">
                  <div class="list-table">
                    <div class="row">
                      <div class="col-md-4">
                        <b>Názov</b>
                      </div>
                      <div class="col-md-8">
                        <b>{{ organization.name }}</b>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <b>IČO</b>
                      </div>
                      <div class="col-md-8">
                        {{ organization.ico }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <b>Adresa</b>
                      </div>
                      <div class="col-md-8">
                        {{ organization.address }} {{ organization.address2 }}<br />
                        {{ organization.zip }} {{ organization.city }}<br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card chart-card">
                <div class="card-heading">
                  <h4 class="card-title">Hodnota vyhlásených zákaziek</h4>
                </div>
                <multi-line-chart-echart
                  v-if="cpvChartsData"
                  :series="cpvChartsSeries" :data="cpvChartsData.estimatedValues" :formatLabel="$format.shortCurrencyFixed2"
                  :yFormat="$format.shortCurrency"
                  :showLastNumValues="5"
                  :minHeight="155"
                />
                <div v-else class="card-block">
                  <span>Momentálne neobsahuje žiadne záznamy</span>
                </div>
              </div>
              <div class="card chart-card">
                <div class="card-heading">
                  <h4 class="card-title">Počet vyhlásených zákaziek</h4>
                </div>
                <multi-line-chart-echart
                  v-if="cpvChartsData"
                  :series="cpvChartsSeries"
                  :data="cpvChartsData.contracts"
                  :showLastNumValues="5"
                  :minHeight="155"
                />
                <div v-else class="card-block">
                  <span>Momentálne neobsahuje žiadne záznamy</span>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-heading row">
              <h1 class="card-title">Verejné obstarávania - <span>{{ organizationName }}</span><span class="text-primary">.</span></h1>
            </div>
            <v-table
              :items="contracts.orders"
              :columns="contractsColumns"
              :sort.sync="tableSort"
              item-key="id"
              class="light"
            >
              <template v-slot:cell(name)="{ item }">
                <order-link :user-type="'user'" :order="item" />
              </template>
              <template v-slot:cell(journalAnnouncementDate)="{ item }">
                {{ item.journalAnnouncementDate | formatDateTime }}
              </template>
              <template v-slot:cell(procedureType)="{ item }">
                {{ item | formatProcedureType }}
              </template>
              <template v-slot:cell(bidsOpenAt)="{ item }">
                {{ item.bidsOpenAt | formatDateTime }}
              </template>
              <template v-slot:cell(state)="{ item }">
                <order-state :state="item.state" display="text"></order-state>
              </template>
            </v-table>
            <div class="card-footer">
              <v-pagination
                v-if="contracts.total"
                class="light"
                :page="page"
                :per-page="perPage"
                :per-page-options="pageSizeOptions"
                :total-elements="contracts.total"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Content Wrapper END -->

    <!-- Footer START -->
    <footer class="content-footer">
      <app-footer />
    </footer>
    <!-- Footer END -->
  </div>
</template>

<script>
import mapValues from 'lodash/mapValues'
import keyBy from 'lodash/keyBy'
import uniq from 'lodash/uniq'
import { bfs } from '@/services/TransparexService'
import moment from 'moment'
import OrderLink from '@/components/order/OrderLink.vue'
import OrderState from '@/components/OrderState.vue'
import VTable from '@/components/ui/Table.vue'
import VPagination from '@/components/ui/Pagination.vue'
import AppLoginHeader from '@/components/AppLoginHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import AppSubHeader from '@/components/order/AppSubHeader.vue'
import RegistrationBanner from '@/components/ui/RegistrationBanner.vue'
import MultiLineChartEchart from '@/components/ui/MultiLineChartEchart.vue'

export default {
  name: 'OrganziationPage',
  components: {
    OrderState,
    OrderLink,
    VTable,
    VPagination,
    AppLoginHeader,
    AppFooter,
    AppSubHeader,
    RegistrationBanner,
    MultiLineChartEchart
  },
  data () {
    return {
      backLink: this.$route.fullPath,
      organization: {},
      contracts: {
        orders: [],
        total: 0
      },
      showFilter: true,
      tableSort: {
        sortBy: 'bidsLimitTo',
        sort: 'desc'
      },
      cpvStatsData: {}
    }
  },
  beforeRouteEnter (to, from, next) {
    // Access the previous route from the 'from' argument
    next(vm => {
      vm.backLink = from.fullPath
    })
  },
  computed: {
    organizationName () {
      return (this.organization && this.organization.name) || ''
    },
    pageSizeOptions () {
      return [5, 10, 25, 50]
    },
    page () {
      return parseInt(this.$route.query.page) || 1
    },
    perPage () {
      const perPage = parseInt(this.$route.query.size)
      const valid = perPage && this.pageSizeOptions.includes(perPage)
      return valid ? perPage : 10
    },
    contractsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov zákazky',
          sortable: true
        },
        {
          key: 'journalAnnouncementDate',
          label: 'Dátum vyhlásenia súťaže',
          sortable: true
        },
        {
          key: 'bidsOpenAt',
          label: 'Dátum otvárania ponúk',
          sortable: true
        },
        {
          key: 'procedureType',
          label: 'Druh postupu',
          sortable: true
        },
        {
          key: 'state',
          label: 'Stav'
        }
      ]
    },
    updatedAtValue () {
      const { updatedAt } = this.filter
      return updatedAt && updatedAt.code && moment().subtract(parseInt(updatedAt.code), 'd')
    },
    fetchParams () {
      return {
        limit: this.perPage,
        page: this.page,
        offset: this.perPage * this.page - this.perPage,
        sort: this.$format.sortString(this.tableSort),
        ico: this.$route.params.ico
      }
    },
    cpvChartsSeries () {
      return {
        all: {
          color: '#3E97EF',
          label: 'Vsetky CPV',
          points: true
        }
      }
    },
    cpvStats () {
      return this.cpvStatsData && this.cpvStatsData.content
    },
    cpvChartsData () {
      const items = this.cpvStats && this.cpvStats.filter(i => i.year)
      if (!items || (items && items.length === 0)) {
        return null
      }
      const years = uniq(items.map(i => i.year)).sort()

      const byCpv = {
        all: keyBy(items, 'year')
      }
      const charts = {
        estimatedValues: 'estimatedValueSum',
        contracts: 'contracts'
      }
      const cpvBandData = (attr, data) => years.map(year => {
        const value = data[year] && data[year][attr] ? data[year][attr] : 0
        return { xAxis: year, value }
      })
      return mapValues(charts, attr => mapValues(byCpv, v => cpvBandData(attr, v)))
    }
  },
  methods: {
    orderState (value) {
      var orderStates = {
        planned: 'V príprave',
        active: 'Prebiehajúce',
        finished: 'Ukončené',
        cancelled: 'Zrušené'
      }
      return orderStates[value]
    },
    async fetchOrganizationData () {
      try {
        const { data } = await this.$http.post('/organization/read', { organization: { ico: this.$route.params.ico } })
        this.organization = data.organization
      } catch (error) {
        this.organziation = {}
      }
    },
    async fetchContracts () {
      try {
        const { data } = await this.$http.post('/order/catalog', this.fetchParams)
        this.contracts = data
      } catch (error) {
        this.contracts = { orders: [], total: 0 }
      }
    },
    async fetchCpvStats () {
      const params = {
        'contractingAuthority.country.codeAlpha2': 'SK',
        'contractingAuthority.cin': this.$route.params.ico,
        'cpvCode.id': 'empty()',
        'size': 100
      }
      const { data } = await bfs.get('/contracting-authorities/cpv-statistics', { params })
      this.cpvStatsData = data
    }
  },
  mounted () {
    this.fetchOrganizationData()
    this.fetchContracts()
    this.fetchCpvStats()
  },
  watch: {
    fetchParams () {
      this.fetchContracts()
    }
  }
}
</script>
<style lang="scss" scoped>
.filters {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0px 10px;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
}
</style>
