<template>
  <div>
    <div class="card chart-card">
      <div class="card-heading">
        <h4 class="card-title">Hodnota vyhlásených zákaziek</h4>
      </div>
      <multi-line-chart-echart
        v-if="cpvChartsData"
        :series="cpvChartsSeries"
        :data="cpvChartsData.estimatedValues"
        :formatLabel="$format.shortCurrencyFixed2"
        :yFormat="$format.shortCurrency"
        :showLastNumValues="5"
        :minHeight="155"
      />
      <div v-else class="card-block">
        <span>Momentálne neobsahuje žiadne záznamy</span>
      </div>
    </div>
    <div class="card chart-card">
      <div class="card-heading">
        <h4 class="card-title">Počet vyhlásených zákaziek</h4>
      </div>
      <multi-line-chart-echart
        v-if="cpvChartsData"
        :series="cpvChartsSeries"
        :data="cpvChartsData.contracts"
        :showLastNumValues="5"
        :minHeight="155"
      />
      <div v-else class="card-block">
        <span>Momentálne neobsahuje žiadne záznamy</span>
      </div>
    </div>
  </div>
</template>

<script>
import MultiLineChartEchart from '@/components/ui/MultiLineChartEchart.vue'
import mapValues from 'lodash/mapValues'
import keyBy from 'lodash/keyBy'
import uniq from 'lodash/uniq'
import { bfs } from '@/services/TransparexService'

export default {
  name: 'OrderOverviewCharts',
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  components: {
    MultiLineChartEchart
  },
  data () {
    return {
      cpvStatsData: {}
    }
  },
  computed: {
    cpvChartsTooltips () {
      return mapValues(this.cpvChartsSeries, i => i.label)
    },
    cpvChartsSeries () {
      return {
        all: {
          color: '#3E97EF',
          label: 'Vsetky CPV',
        }
      }
    },
    cpvStats () {
      return this.cpvStatsData.content
    },
    cpvChartsData () {
      const items = this.cpvStats && this.cpvStats.filter(i => i.year)
      if (!items || (items && items.length === 0)) {
        return null
      }
      const years = uniq(items.map(i => i.year)).sort()

      const byCpv = {
        all: keyBy(items, 'year')
      }
      const charts = {
        estimatedValues: 'estimatedValueSum',
        contracts: 'contracts'
      }
      const cpvBandData = (attr, data) => years.map(year => {
        const value = data[year] && data[year][attr] ? data[year][attr] : 0
        return { xAxis: year, value }
      })
      return mapValues(charts, attr => mapValues(byCpv, v => cpvBandData(attr, v)))
    },
  },
  methods: {
    async fetchCpvStats () {
      const params = {
        'contractingAuthority.country.codeAlpha2': 'SK',
        'contractingAuthority.cin': this.order.organization.ico,
        'cpvCode.id': 'empty()',
        'size': 100
      }
      const { data } = await bfs.get('/contracting-authorities/cpv-statistics', { params })
      this.cpvStatsData = data
    }
  },
  mounted () {
    this.fetchCpvStats()
  }
}
</script>
