<template>
  <div class="card" id="order-settings-commision-add">
    <div class="card-heading">
      <h4 class="card-title">Pridať nového člena komisie</h4>
    </div>
    <div class="card-block">
      <div class="row form-group">
        <label class="col-md-3 control-label" for="firstname">Meno <small>*</small></label>
        <div class="col-md-9">
          <input id="firstname" type="text" v-model="member.firstname" placeholder="Meno" :class="{ error: form.firstname.error, valid: form.firstname.valid }" class="form-control" required="required" aria-required="true">
          <label v-if="form.firstname.error" id="firstname-error" class="error" for="firstname">Meno musí byť vyplnené.</label>
        </div>
      </div>
      <div class="row form-group">
        <label class="col-md-3 control-label" for="lastname">Priezvisko <small>*</small></label>
        <div class="col-md-9">
          <input id="lastname" type="text" v-model="member.lastname" placeholder="Priezvisko" :class="{ error: form.lastname.error, valid: form.lastname.valid }" class="form-control" required="required" aria-required="true">
          <label v-if="form.lastname.error" id="lastname-error" class="error" for="lastname">Priezvisko musí byť vyplnené.</label>
        </div>
      </div>
      <div class="row form-group">
        <label class="col-md-3 control-label" for="title1">Akademický titul<small></small></label>
        <div class="col-md-9">
          <input id="title1" type="text" v-model="member.title1" placeholder="Akademický titul" class="form-control">
        </div>
      </div>
      <div class="row form-group">
        <label class="col-md-3 control-label" for="title2">Vedecká hodnosť<small></small></label>
        <div class="col-md-9">
          <input id="title2" type="text" v-model="member.title2" placeholder="Vedecká hodnosť" class="form-control">
        </div>
      </div>
      <div class="row form-group">
        <label class="col-md-3 control-label" for="city">Mesto <small>*</small></label>
        <div class="col-md-9">
          <input id="city" type="text" v-model="member.city" placeholder="Mesto" :class="{ error: form.city.error, valid: form.city.valid }" class="form-control" required="required" aria-required="true">
          <label v-if="form.city.error" id="city-error" class="error" for="city">Mesto musí byť vyplnené.</label>
        </div>
      </div>
      <div class="row form-group">
        <label class="col-md-3 control-label" for="member-function">Funkcia <small>*</small></label>
        <div class="col-md-9">
          <multiselect
            v-model="member.type"
            :options="typeOptions"
            track-by="code"
            label="text"
            openDirection="bottom"
            :clear-on-select="false"
            :close-on-select="true"
            :searchable="true"
            :show-labels="false"
            placeholder="Vyberte funkciu"
          />
        </div>
      </div>
      <div class="row form-group">
        <label class="col-md-3 control-label" for="member-valid-from">Dátum menovania <small>*</small></label>
        <div class="col-md-9">
          <date-input name="member-valid-from" v-model="member.event.validFrom" :value="member.event.validFrom"></date-input>
          <label v-if="form.validFrom.error" class="error" for="member-valid-from">Dátum menovania musí byť vyplnený.</label>
        </div>
      </div>
      <div class="row form-group">
        <label class="col-md-3 control-label" for="member-valid-from">Menovací dekrét</label>
        <div class="col-md-9">
          <input type="file" id="file" @change="setFile">
        </div>
      </div>
    </div>
    <div class="card-footer border top text-right">
      <button class="btn btn-tenderia-grey-blue" @click="goBack"><i class="icon-arrow-left"></i> Späť</button>
      <button class="btn btn-tenderia-grey-blue" @click="addMemberButton"><i class="icon-save"></i> Uložiť</button>
    </div>
  </div>
</template>

<script>

import DateInput from '@/components/forms/DateInput.vue'
import validator from '@/components/validator.js'
import uploader from '@/components/utils/uploader.js'
import Multiselect from 'vue-multiselect'

export default {
  name: 'AdminCommissionAdd',
  components: { DateInput, Multiselect },
  inject: ['order', 'eventBus'],
  data () {
    return {
      member: {
        firstname: '',
        lastname: '',
        title1: '',
        title2: '',
        city: '',
        type: 'member',
        state: 'active',
        event: {
          type: 'nomination',
          validFrom: ''
        }
      },
      files: [],
      form: {
        firstname: {
          error: false,
          valid: false
        },
        lastname: {
          error: false,
          valid: false
        },
        city: {
          error: false,
          valid: false
        },
        validFrom: {
          error: false,
          valid: false
        }
      },
      typeOptions: [
        { code: 'member', text: 'Člen komisie' },
        { code: 'chairman', text: 'Predseda' }
      ]
    }
  },
  watch: {
    'member.firstname': function () {
      this.validateFirstname()
    },
    'member.lastname': function () {
      this.validateLastname()
    },
    'member.city': function () {
      this.validateCity()
    }
  },
  methods: {
    validateFirstname: function () {
      var status = this.form.firstname
      status.error = false
      status.valid = false
      if (validator.validateFirstname(this.member.firstname)) {
        status.valid = true
      } else {
        status.error = true
      }
    },
    validateCity: function () {
      var status = this.form.city
      status.error = false
      status.valid = false
      if (validator.validateCity(this.member.city)) {
        status.valid = true
      } else {
        status.error = true
      }
    },
    validateValidFrom: function () {
      var validFrom = this.member.event.validFrom
      var state = this.form.validFrom
      state.error = state.valid = false
      if (validFrom && validFrom.length > 0) {
        state.valid = true
      } else {
        state.error = true
      }
    },
    validateLastname: function () {
      var status = this.form.lastname
      status.error = false
      status.valid = false
      if (validator.validateLastname(this.member.lastname)) {
        status.valid = true
      } else {
        status.error = true
      }
    },
    validate: function () {
      this.validateFirstname()
      this.validateLastname()
      this.validateCity()
      this.validateValidFrom()

      return this.form.firstname.valid && this.form.lastname.valid && this.form.city.valid && this.form.validFrom.valid
    },
    showLoading: function () {
      $('#order-settings-commision-add .card-block').LoadingOverlay('show')
    },
    hideLoading: function () {
      $('#order-settings-commision-add .card-block').LoadingOverlay('hide')
    },
    goBack: function () {
      window.history.go(-1)
    },
    onError: function (error) {
      this.hideLoading()
      let vm = this
      setTimeout(function () {
        vm.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error && error?.response?.data.error)
      }, 500)
    },
    setFile: function (event) {
      if (event.target.files.length === 1) {
        this.files.push(event.target.files[0])
      } else {
        this.files.splice(0, 1)
      }
    },
    addMemberButton: function () {
      if (!this.validate()) {
        return
      }
      this.showLoading()
      if (this.files.length > 0) {
        uploader.init(this.addMember, this.onError, { order: this.order.id, access: 'order' }, this.$http)
        uploader.startUpload(this.files, null)
      } else {
        this.addMember([])
      }
    },
    addMember: function (attachments) {
      var data = {
        order: {
          id: this.order.id
        },
        member: this.member
      }
      data.member.attachments = attachments
      data.member.type = this.member.type.code
      var vm = this
      this.$http.post('/order/member/set', data).then(function () {
        setTimeout(function () {
          vm.eventBus.$emit('order-updated')
          vm.hideLoading()
          vm.goBack()
        }, 1000)
      }).catch((error) => {
        vm.onError(error)
      })
    }
  }
}
</script>
