import Bus from './bus'
import TenderiaUploadProgress from './TenderiaUploadProgress.vue'
import TenderiaProgress from './TenderiaProgress.vue'

export default {
  install (Vue) {
    Vue.prototype.$tenderiaProgressModal = new Vue({
      name: '$tenderiaProgressModal',

      created () {
        Bus.$on('new', options => {
          this.open(options)
        })
        Bus.$on('close', () => {
          this.close()
        })
        this.$on('vuedals:opened', () => {
          console.log('vuedals:opened')
        })
      },

      methods: {
        open (options) {
          let components = {
            default: TenderiaProgress,
            upload: TenderiaUploadProgress
          }
          this.$emit('vuedals:new', {
            name: 'upload-progressbar',
            escapable: false,
            dismissable: false,
            title: options.title,
            component: components[options.type]
          })
        },
        close () {
          this.$emit('vuedals:close')
        }
      }
    })

    Vue.mixin({
      created () {
        this.$on('epm:new', options => {
          Bus.$emit('close')
          Bus.$emit('new', options)
        })
        this.$on('epm:close', () => {
          Bus.$emit('close')
        })
      }
    })
  }
}

export {
  Bus
}
