<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-heading">
        <h4 class="card-title">{{ $route.params.id ?  'Upraviť dodávateľa' : 'Pridať dodávateľa' }} </h4>
        <div class="actions">
          <v-badge v-if="form.manualMode" color="primary" glow>
            <small>Manuálne zadané údaje</small>
          </v-badge>
        </div>
      </div>
      <div class="card-block">
        <div id="new-supplier-form">
          <div class="row">
            <div class="col-md-8 form-group">
              <label class="control-label" form="name">Názov spoločnosti<small>*</small></label>
              <rpo-search
                placeholder="Názov"
                :value="form.manualMode ? supplier.name : form.organization"
                :manualMode="form.manualMode"
                @manualModeChange="onManualModeChange"
                @input="updateOrganizationName"
                :error="supplier.getField('name').hasErrors()"
              >
                <slot slot="prepend">
                  <span/>
                </slot>
              </rpo-search>
              <label v-if="supplier.getField('name').hasErrors()" class="error">
                {{ supplier.getField('name').errors | firstMessage }}
              </label>
            </div>
            <div class="col-md-2 form-group">
              <label class="control-label" form="ic">IČO <small>*</small></label>
              <rpo-search
                placeholder="IČO"
                :value="form.manualMode ? supplier.ico : form.organization"
                :error="supplier.getField('ico').hasErrors()"
                itemText="cin"
                :manualMode="form.manualMode"
                @manualModeChange="onManualModeChange"
                @input="updateOrganizationCin"
              >
                <slot slot="prepend">
                  <span/>
                </slot>
              </rpo-search>
              <label v-if="supplier.getField('ico').hasErrors()" class="error">
                {{ supplier.getField('ico').errors | firstMessage }}
              </label>
            </div>

            <div class="col-md-2 form-group">
              <label class="text-dark text-bold">IČ DPH</label>
              <input type="text" v-model="supplier.icdph" class="form-control" :class="{ error: supplier.getField('icdph').hasErrors() }" />
              <label v-if="supplier.getField('icdph').hasErrors()" class="error">
                {{ supplier.getField('icdph').errors | firstMessage }}
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-9 form-group">
              <label class="text-dark text-bold">Ulica <small>*</small></label>
              <input type="text" v-model="supplier.address" class="form-control" :class="{ error: supplier.getField('address').hasErrors() }" />
              <label v-if="supplier.getField('address').hasErrors()" class="error">
                {{ supplier.getField('address').errors | firstMessage }}
              </label>
            </div>
            <div class="col-md-3 form-group">
              <label class="text-dark text-bold">Číslo domu <small>*</small></label>
              <input type="text" v-model="supplier.address2" class="form-control" :class="{ error: supplier.getField('address2').hasErrors() }" />
              <label v-if="supplier.getField('address2').hasErrors()" class="error">
                {{ supplier.getField('address2').errors | firstMessage }}
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 form-group">
              <label class="text-dark text-bold">PSČ <small>*</small></label>
              <input type="text" v-model="supplier.zip" class="form-control" :class="{ error: supplier.getField('zip').hasErrors() }" />
              <label v-if="supplier.getField('zip').hasErrors()" class="error">
                {{ supplier.getField('zip').errors | firstMessage }}
              </label>
            </div>
            <div class="col-md-5 form-group">
              <label class="text-dark text-bold">Mesto <small>*</small></label>
              <input type="text" v-model="supplier.city" class="form-control" :class="{ error: supplier.getField('city').hasErrors() }" />
              <label v-if="supplier.getField('city').hasErrors()" class="error">
                {{ supplier.getField('city').errors | firstMessage }}
              </label>
            </div>
            <div class="col-md-4 form-group">
              <label class="text-dark text-bold">Krajina <small>*</small></label>
              <country-select v-model="supplier.country" :value="supplier.country"></country-select>
              <label v-if="supplier.getField('country').hasErrors()" class="error">
                {{ supplier.getField('country').errors | firstMessage }}
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 form-group">
              <label class="text-dark text-bold">Email <small>*</small></label>
              <input type="text" v-model="supplier.email" class="form-control" :class="{ error: supplier.getField('email').hasErrors() }" />
              <label v-if="supplier.getField('email').hasErrors()" class="error">
                {{ supplier.getField('email').errors | firstMessage }}
              </label>
            </div>
            <div class="col-md-6 form-group">
              <label class="text-dark text-bold">Telefón</label>
              <input type="text" v-model="supplier.phone" class="form-control" :class="{ error: supplier.getField('phone').hasErrors() }" />
              <label v-if="supplier.getField('phone').hasErrors()" class="error">
                {{ supplier.getField('phone').errors | firstMessage }}
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 form-group">
              <label class="text-dark text-bold">Kontaktná osoba</label>
              <input type="text" v-model="supplier.contactPerson" class="form-control" :class="{ error: supplier.getField('contactPerson').hasErrors() }" />
              <label v-if="supplier.getField('contactPerson').hasErrors()" class="error">
                {{ supplier.getField('contactPerson').errors | firstMessage }}
              </label>
            </div>
          </div>
          <!-- <div class="row" v-for="(supplierContact, index) in supplier.contacts" :key="'suppContact'+index">
            <div class="col-md-4 form-group">
              <label class="text-dark text-bold">Email <small>*</small></label>
              <input type="text" v-model="supplierContact.email" class="form-control" :class="{ error: supplierContact.getField('email').hasErrors() }" />
              <label v-if="supplierContact.getField('email').hasErrors()" class="error">
                {{ supplierContact.getField('email').errors | firstMessage }}
              </label>
            </div>
            <div class="col-md-4 form-group">
              <label class="text-dark text-bold">Telefón</label>
              <input type="text" v-model="supplierContact.phone" class="form-control" :class="{ error: supplierContact.getField('phone').hasErrors() }" />
              <label v-if="supplierContact.getField('phone').hasErrors()" class="error">
                {{ supplierContact.getField('phone').errors | firstMessage }}
              </label>
            </div>
            <div class="col-md-3 form-group">
              <label class="text-dark text-bold">Kontaktná osoba</label>
              <input type="text" v-model="supplierContact.name" class="form-control" :class="{ error: supplierContact.getField('name').hasErrors() }" />
              <label v-if="supplierContact.getField('name').hasErrors()" class="error">
                {{ supplierContact.getField('name').errors | firstMessage }}
              </label>
            </div>
            <div class="row align-items-center no-gutters form-group">
              <a
                href=""
                @click.prevent="removeContactPerson(index)"
                class="text-danger font-size-27"
                title="Zmazať"
              >
                <i class="icon-delete"></i>
              </a>
            </div>
          </div>
          <div class="row no-gutters justify-content-end">
            <button class="btn btn-tenderia-blue" @click="addContactPerson">
              <i class="icon-add font-size-14"></i> Pridať kotaktnú osobu
            </button>
          </div> -->
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <button class="btn btn-tenderia-grey-blue btn-inverse" @click.prevent="goBack"><i class="icon-arrow-left"></i> Späť</button>
          <button class="btn btn-success" @click="save" :disabled="supplier.hasErrors()"><i class="icon-save"></i> Uložiť</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountrySelect from '@/components/forms/CountrySelect.vue'
import RpoSearch from '@/components/ui/RpoSearch.vue'
/* import { SupplierContact } from '../../../../models/Supplier' */
import VBadge from '@/components/ui/Badge.vue'

export default {
  name: 'SupplierForm',
  inject: ['order', 'eventBus', 'routePrefix'],
  components: { CountrySelect, RpoSearch, VBadge },
  data () {
    return {
      form: {
        organization: {},
        manualMode: false
      },
      supplier: new this.$models.Supplier({
        vm: this,
        dataKey: 'supplier'
        /* contacts: [] */
      })
    }
  },
  methods: {
    /* addContactPerson () {
    addContactPerson () {
      this.supplier.contacts.push(
        new SupplierContact({
          vm: this,
          dataKey: 'contact',
          name: '',
          email: '',
          phone: ''
        })
      )
      this.supplier.$validate()
    },
    removeContactPerson (index) {
      this.supplier.contacts.splice(index, 1)
      this.supplier.$validate()
    }, */
    updateOrganizationName (val) {
      if (this.form.manualMode) {
        this.supplier.name = val
      } else {
        this.form.organization = val
      }
    },
    updateOrganizationCin (val) {
      if (this.form.manualMode) {
        this.supplier.ico = val
      } else {
        this.form.organization = val
      }
    },
    onManualModeChange (val) {
      this.form.manualMode = val
      this.form.organization = null
      this.supplier.name = ''
      this.supplier.ico = ''
    },
    goBack () {
      if (this.order) {
        this.$router.push({ name: `${this.routePrefix}admin-suppliers` })
      } else {
        this.$router.push({ name: 'settingsSuppliers' })
      }
    },
    showLoading () {
      $('#new-supplier-form').LoadingOverlay('show')
    },
    hideLoading () {
      $('#new-supplier-form').LoadingOverlay('hide')
    },
    async createOrderSupplier (supplier) {
      this.showLoading()
      let data = {
        supplier: supplier
      }
      try {
        let { data: { organization } } = await this.$http.post('/organization/supplier/set', data)
        let formData = {
          order: {
            id: this.order.id
          },
          supplier: Object.assign({ organization: organization }, supplier)
        }
        await this.$http.post('/order/supplier/set', formData)
        this.eventBus.$emit('order-updated')
        let message = `Dodávateľ  ${supplier.name} (${supplier.ico}) bol priradený do zákazky.`
        if (this.order.state === 'active') {
          message += ' Pozvánka bola automaticky odoslaná na kontaktný email dodávateľa.'
        } else {
          message += ' Pozvánka bude odoslaná na kontaktný email dodávateľa po spustení zákazky.'
        }
        this.OkAlert({ text: message })
        this.$router.push({ name: `${this.routePrefix}admin-suppliers` })
        this.eventBus.$emit('order-updated')
        this.reset()
      } catch (error) {
        let message = 'Nastala neočakávaná chyba. Skúste to znovu.'
        console.log(error)
        if (error?.response?.data.error && error?.response?.data.error.code === 410) {
          message = 'Nie je možné pridávať dodávateľov do zákazky po lehote na predkladanie ponúk.'
        }
        this.ErrorAlert({ text: message }, error?.response?.data.error)
      }
      this.hideLoading()
    },
    async editOrderSupplier (supplier) {
      this.showLoading()
      let data = {
        order: {
          id: this.order.id
        },
        supplier: Object.assign({ organization: this.supplier.organization }, supplier)
      }
      try {
        await this.$http.post('/order/supplier/set', data)
        this.OkAlert({ text: `Dodávateľ ${supplier.name} (${supplier.ico}) bol úspešne aktualizovaný.` })
        this.$router.push({ name: `${this.routePrefix}admin-suppliers` })
        this.eventBus.$emit('order-updated')
      } catch (error) {
        this.ErrorAlert({ text: 'Nastala neočakávaná chyba. Skúste to znovu.' }, error?.response?.data.error)
      }
      this.hideLoading()
    },
    async saveOrganizationSupplier (supplier) {
      this.showLoading()
      let data = {
        supplier: supplier
      }
      try {
        await this.$http.post('/organization/supplier/set', data)
        let operation = this.$route.params.id ? 'aktualizovaný' : 'pridaný'
        this.OkAlert({ text: `Dodávateľ ${supplier.name} (${supplier.ico}) bol úspešne ${operation}.` })
        this.$router.push({ name: 'settingsSuppliers' })
      } catch (error) {
        this.ErrorAlert({ text: 'Nastala neočakávaná chyba. Skúste to znovu.' }, error?.response?.data.error)
      }
      this.hideLoading()
    },
    save () {
      try {
        this.supplier.$validate()
        if (this.order) {
          this.supplier.organization ? this.editOrderSupplier(this.supplier) : this.createOrderSupplier(this.supplier)
        } else {
          this.saveOrganizationSupplier(this.supplier)
        }
      } catch (error) {
        if (error.code && error.code === 422) {
          this.supplier.$validate()
        } else {
          this.ErrorAlert({ text: 'Nastala neočakávaná chyba. Skúste to znovu.' }, error?.response?.data.error)
          console.log(error)
        }
      }
    }
  },
  watch: {
    supplier: {
      handler (supplier) {supplier.$validate({ debounce: 500 })},
      deep: false,
      immediate: true
    },
    'form.organization' (value) {
      if (value && value.cin && !this.form.manualMode) {
        this.supplier.address = value.address.street
        this.supplier.address2 = value.address.building_number
        this.supplier.city = value.address.municipality
        this.supplier.zip = value.address.postal_code.replace(/\s+/g, '')
        this.supplier.country = value.address.country.toUpperCase()
        this.supplier.name = value.name
        this.supplier.ico = value.cin
      }
    }
  },
  async created () {
    if (!this.$route.params.id) {
      return
    }
    this.showLoading()

    try {
      if (this.order) {
        const supplier = this.order.suppliers.find(element => element._id === this.$route.params.id)
        Object.assign(this.supplier, supplier)
        this.$set(this.form.organization, 'name', supplier.name)
        this.$set(this.form.organization, 'cin', supplier.ico)
      } else {
        let { data } = await this.$http.post('/organization/supplier/get', { supplier: { id: this.$route.params.id } })
        Object.assign(this.supplier, data.supplier)
        this.$set(this.form.organization, 'name', data.supplier.name)
        this.$set(this.form.organization, 'cin', data.supplier.ico)
      }
      this.hideLoading()
    } catch (error) {
      console.log(error)
      this.hideLoading()
      this.ErrorAlert({ text: 'Nepodarilo sa načítať informácie o dodávateľovi.' }, error?.response?.data.error)
    }
  }
}
</script>
