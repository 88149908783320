<template>
  <div class="card" id="add-new-document">
    <div class="card-heading">
      <h4 class="card-title">Pridať verejný dokument k zákazke</h4>
    </div>
    <div class="card-block">
      <form>
        <div class="row mt-3">
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label" for="name">Názov <small>*</small></label>
              <input id="name" type="text" v-model.lazy="name" class="form-control" :class="{ error: form.name.error, valid: form.name.valid }" required="required" aria-required="true">
              <label v-if="form.name.error" class="error" for="name">Názov súboru musí byť vyplnený.</label>
            </div>
            <div class="form-group">
              <label class="control-label" for="document-type">Typ dokumentu</label>
              <multiselect
                v-model="documentType"
                :options="documentTypeOptions"
                track-by="code"
                label="text"
                openDirection="bottom"
                :clear-on-select="false"
                :close-on-select="true"
                :searchable="true"
                :show-labels="false"
                placeholder="Vyberte typ dokumentu"
              />
              <label v-if="form.type.error" class="error" for="document-type">Musíte vybrať typ dokumentu.</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label" for="file-upload">Súbory</label>
              <file-upload-element v-model="files" :attachments="attachments" @remove-saved="removeSavedFile"></file-upload-element>
              <label v-if="form.files.error" class="error" for="file-upload">Musíte pridať aspoň jeden súbor.</label>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="card-footer">
      <div class="row">
        <button @click="goBack" class="btn btn-tenderia-grey-blue btn-inverse"><i class="icon-arrow-left"></i> Späť</button>
        <button class="btn btn-success" @click="uploadDocument"><i class="icon-save"></i> Uložiť dokument</button>
      </div>
    </div>
  </div>
</template>

<script>
import FileUploadElement from '@/components/forms/FileUploadElement.vue'
import uploader from '@/components/utils/uploader.js'
import Multiselect from 'vue-multiselect'

export default {
  name: 'AdminDocumentsAdd',
  components: { FileUploadElement, Multiselect },
  inject: ['eventBus', 'order'],
  data () {
    return {
      name: '',
      files: [],
      attachments: [],
      documentType: '',
      documentTypeOptions: [
        { code: 'sp', text: 'Súťažné podklady' },
        { code: 'vsp', text: 'Vysvetlenie súťažných podkladov' },
        { code: 'zm', text: 'Zmluva' },
        { code: 'izm', text: 'Informácia o zverejnení zmluvy' },
        { code: 'iop', text: 'Informácia o otváraní ponúk' },
        { code: 'ivvp', text: 'Informácia o výsledku vyhodnotenia ponúk' },
        { code: 'onpp', text: 'Odôvodnenie nezrušenia použitého postupu' },
        { code: 'pu', text: 'Ponuky uchádzačov' },
        { code: 'sz', text: 'Správa o zákazke' },
        { code: 'ssup', text: 'Suma skutočne uhradeného plnenia' },
        { code: 'zop', text: 'Zápisnica z otvárania ponúk' },
        { code: 'zvsu', text: 'Zápisnica z vyhodnotenia splnenia podmienok účasti' },
        { code: 'zvp', text: 'Zápisnica z vyhodnotenia ponúk' },
        { code: 'zs', text: 'Zoznam subdodávateľov' },
        { code: 'id', text: 'Iný dokument k zákazke' }
      ],
      form: {
        name: {
          error: false,
          valid: false
        },
        type: {
          error: false,
          valid: false
        },
        files: {
          error: false,
          valid: false
        }
      }
    }
  },
  methods: {
    async fetchFiles () {
      const fetchParams = {
        parent: this.$route.params.id,
        limit: 1000
      }
      const { data } = await this.$http.post('/file/list', fetchParams)
      this.attachments = data.files
    },
    async removeSavedFile (value) {
      try {
        await this.$http.post('/file/set', { file: { id: value.fileList[value.index].id, parent: null } })
        this.attachments.splice(value.index, 1)
      } catch (error) {
        this.ErrorAlert({ text: 'Pri spracovaní nastala chyba.' }, error?.response?.data.error)
      }
    },
    validateDocumentName () {
      var status = this.form.name
      var name = this.name
      status.valid = status.error = false
      if (name && name.length > 1 && name.length < 251) {
        status.valid = true
      } else {
        status.error = true
      }
    },
    validateType () {
      var status = this.form.type
      status.valid = status.error = false
      if (this.documentType && this.documentType.code.length > 1) {
        status.valid = true
      } else {
        status.error = true
      }
    },
    validateFiles () {
      var status = this.form.files
      if (this.$route.params.id) {
        status.valid = true
      } else {
        status.valid = status.error = false
        if (this.files.length > 0) {
          status.valid = true
        } else {
          status.error = true
        }
      }
    },
    validate () {
      this.validateDocumentName()
      this.validateType()
      this.validateFiles()
      return this.form.files.valid && this.form.type.valid && this.form.name.valid
    },
    goBack () {
      window.history.go(-1)
    },
    uploadFinish () {
      let vm = this
      setTimeout(function () {
        vm.$emit('epm:close')
        vm.eventBus.$emit('order-updated')
        vm.OkAlert({ text: 'Dokument bol úspešne uložený' }).then(() => {
          window.history.go(-1)
        })
      }, 500)
    },
    uploadError (error) {
      let vm = this
      setTimeout(function () {
        vm.$emit('epm:close')
        vm.ErrorAlert({ text: 'Pri ukladaní dokumentu nastala chyba.' }, error?.response?.data.error)
      }, 500)
    },
    async uploadDocument () {
      if (!this.validate()) {
        return
      }
      this.$emit('epm:new', { title: 'Ukladám prílohy dokumentu', type: 'upload' })
      var data = {
        file: {
          id: this.$route.params.id,
          name: this.name,
          description: this.description,
          type: this.documentType.code,
          access: 'free',
          kind: 'document',
          order: this.order.id
        }
      }

      try {
        const response = await this.$http.post('/file/set', data)
        if (this.$route.params.id) {
          if (this.files.length === 0) {
            this.uploadFinish()
            return
          }
          uploader.init(this.uploadFinish(), this.uploadError, { parent: this.$route.params.id, access: 'free' }, this.$http)
        } else {
          this.parent = response.data.id
          uploader.init(this.attachFile, this.uploadError, { parent: response.data.id, access: 'free' }, this.$http)
        }
        uploader.startUpload(this.files, null)
      } catch (error) {
        console.log(error)
        this.uploadError()
      }
    },
    attachFile () {
      var data = {
        order: {
          id: this.order.id
        },
        attachment: {
          id: this.parent
        }
      }
      var vm = this
      this.$http.post('/order/attachment/add', data).then(function () {
        vm.uploadFinish()
      }).catch((error) => {
        vm.uploadError(error)
      })
    }
  },
  mounted () {
    if (this.$route.params.id) {
      const { name, type } = this.order.attachments.find(item => item.id === this.$route.params.id)
      this.name = name
      this.documentType = this.documentTypeOptions.find(docType => docType.code === type)
      this.fetchFiles()
    }
  }
}
</script>
