import { Bus } from '@/plugins/TenderiaProgressModal'
import { Uploader } from '@/components/tenderia-uploader.js'
var db = require('mime-db')
var mime = require('mime-type')(db)

const uploader = {
  files: [],
  uploadedFilesId: [],
  current: null,
  onDone: null,
  uploadStatus: {},
  $http: {},
  fields: {},
  onError: null,
  uploadToken: '',
  authorization: '',
  offset: 1,
  getFiles: function () {
    return this.files
  },
  init: function (onDone, onError, fields, transport, authorization) {
    this.onDone = onDone
    this.onError = onError
    this.fields = fields
    this.$http = transport
    this.authorization = authorization || localStorage.getItem('token')
  },
  startUpload: function (files, options) {
    this.files = files
    this.uploadedFilesId = []
    this.current = 0
    this.next()
    if (options && options.totalCount) {
      this.uploadStatus.total = options.totalCount
    } else {
      this.uploadStatus.total = files.length
    }
    if (options && options.offset) {
      this.offset = options.offset + 1
    }
  },
  next: function () {
    if (this.current < this.files.length) {
      this.setFile()
    } else {
      this.onDone(this.uploadedFilesId)
    }
  },
  setStatusMessage: function (percent) {
    this.uploadStatus.currentIndex = this.current + this.offset
    this.uploadStatus.currentName = this.files[this.current].name
    this.uploadStatus.currentPercent = percent
    Bus.$emit('upload:status', this.uploadStatus)
  },
  setFile: async function () {
    this.setStatusMessage(0)
    var data = {
      file: Object.assign({}, this.fields)
    }
    if (!data.file.name) {
      data.file.name = this.files[this.current].name
    }
    data.file.mime = mime.lookup(this.files[this.current].name)
    try {
      const response = await this.$http.post('/file/set', data)
      this.uploadedFilesId.push(response.data.id)
      this.uploadFile(response.data.uploadToken)
    } catch (error) {
      console.log(error)
      this.onError(error)
    }
  },
  uploadFile: function (uploadToken) {
    let uploader = new Uploader({ authorization: this.authorization, chunkSize: 524288 })
    uploader.on('progress', function onProgress (evt, id, message) {
      this.setStatusMessage(message)
    }.bind(this))
    uploader.on('complete', function onComplete () {
      this.setStatusMessage(100)
      this.current++
      this.next()
    }.bind(this))
    uploader.on('error', this.onError)
    uploader.addFile(this.files[this.current], process.env.VUE_APP_BACKEND_URL + '/file/upload', uploadToken)
    uploader.start()
  },
  getFileType: function (index) {
    return this.files[index].type
  }
}

export default uploader
