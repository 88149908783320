<template>
  <div class="card" id="public-add-new-private-message">
    <div class="card-heading">
      <h4 class="card-title">{{ subject ? `Správa - ${subject}` : 'Nová správa'}}</h4>
    </div>
    <div class="card-block">
      <form>
        <div class="row">
          <div :class="{'col-md-6': order.divided, 'col-md-8': !order.divided}">
            <div class="form-group">
              <label for="subject" class="text-dark text-bold">Predmet <small>*</small></label>
              <input id="subject" type="text" v-model.lazy="subject" class="form-control" :class="{ error: form.subject.error, valid: form.subject.valid }" required="required" aria-required="true">
              <label v-if="form.subject.error" class="error" for="subject">Predmet správy musí byť vyplnený a môže obsahovať max. 250 znakov.</label>
            </div>
          </div>
          <div class="col-md-3" v-if="order.divided">
            <div class="form-group">
              <label for="part" class="text-dark text-bold">Časť zákazky <small>*</small></label>
              <multiselect
                v-model="part"
                :options="order._parts"
                track-by="id"
                label="name"
                openDirection="bottom"
                :clear-on-select="false"
                :searchable="true"
                placeholder="Vyberte časť"
                :show-labels="false"
              />
              <label v-if="form.part.error" class="error" for="part">Musíte vybrať časť zákazky.</label>
            </div>
          </div>
          <div :class="{'col-md-3': order.divided, 'col-md-4': !order.divided}">
            <div class="form-group">
              <label for="select-message-type" class="text-dark text-bold">Typ správy <small>*</small></label>
              <multiselect
                v-model="type"
                :options="messageTypeOptions"
                track-by="code"
                label="text"
                openDirection="bottom"
                :clear-on-select="false"
                :close-on-select="true"
                :searchable="true"
                :show-labels="false"
                placeholder="Vyberte typ správy"
              />
              <label v-if="form.type.error" class="error" for="select-message-type">Musíte zvoliť typ správy.</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="text-dark text-bold">Správa</label>
              <vue-mce v-model="body" :config="mceConfig" ref="bodyEditor"/>
              <label v-if="form.body.error" class="error">Počet znakov: {{ bodyLength }} z {{ bodyLengthLimit }}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label class="text-dark text-bold">Súbor s prílohou</label>
            <file-upload-element v-model="files" :attachments="attachments"></file-upload-element>
          </div>
        </div>
      </form>
    </div>
    <div class="card-footer">
      <div class="row">
        <button class="btn btn-tenderia-grey-blue btn-inverse" @click="goBack"><i class="icon-arrow-left"></i> Späť</button>
        <button class="btn btn-warning" @click="setMessageButton('draft')"><i class="icon-save"></i> Uložiť ako draft</button>
        <button class="btn btn-success" @click="setMessageButton('sent')"><i class="icon-send"></i> Odoslať správu</button>
      </div>
    </div>
  </div>
</template>

<script>
import FileUploadElement from '@/components/forms/FileUploadElement.vue'
import uploader from '@/components/utils/uploader.js'
import Multiselect from 'vue-multiselect'

export default {
  name: 'UserCommunicationAdd',
  inject: ['order', 'routePrefix'],
  components: { FileUploadElement, Multiselect },
  data () {
    return {
      bodyLengthLimit: 64000,
      files: [],
      part: '',
      subject: '',
      body: '',
      bodyLength: 0,
      type: '',
      signature: '',
      messageState: 'draft',
      attachments: [],
      form: {
        subject: {
          error: false,
          valid: false
        },
        type: {
          error: false,
          valid: false
        },
        part: {
          error: false,
          valid: false
        },
        body: {
          error: false,
          valid: false
        }
      },
      mceConfig: {
        height: 300,
        inline: false,
        theme: 'modern',
        language: 'sk',
        fontsize_formats: '8px 10px 12px 14px 16px 18px 20px 22px 24px 26px 39px 34px 38px 42px 48px',
        plugins: 'print colorpicker textcolor lists link table paste',
        toolbar1: 'formatselect fontsizeselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat'
      },
      messageTypeOptions: [
        { code: 'zvsp', text: 'Žiadosť o vysvetlenie súťažných podkladov' },
        { code: 'ozvpd', text: 'Odpoveď na žiadosť o vysvetlenie alebo doplnenie predložených dokladov' },
        { code: 'ozvp', text: 'Odpoveď na žiadosť o vysvetlenie ponuky' },
        { code: 'ozvnp', text: 'Odpoveď na žiadosť o vysvetlenie mimoriadne nízkej ponuky' },
        { code: 'ozpde', text: 'Odpoveď na žiadosť o predloženie dokladov nahradených Jednotným európskym dokumentom' },
        { code: 'oznio', text: 'Odpoveď na žiadosť o nahradenie inej osoby' },
        { code: 'ozns', text: 'Odpoveď na žiadosť o nahradenie subdodávateľa' },
        { code: 'zon', text: 'Žiadosť o nápravu' },
        { code: 'nam', text: 'Námietka' },
        { code: 'id', text: 'Iný dokument' }
      ]
    }
  },
  created () {
    this.signature = `<p></p><p style="margin:0px; line-height:1.5;"><span style="font-size:14px;"><em><strong>${this.$globals.user.firstname} ${this.$globals.user.lastname}</strong></em></span></p>
      <p style="margin:0px; line-height:1.5;"><span style="font-size:14px;"><em><strong>${this.$globals.userOrganization.name}</strong></em></span></p>
      <p style="margin:0px; line-height:1.5;"><span style="font-size:14px;"><em><strong>${this.$globals.userOrganization.address} ${this.$globals.userOrganization.address2}, ${this.$globals.userOrganization.zip}, ${this.$globals.userOrganization.city}</strong></em></span></p>`

    if (this.$route.params.action === 'reply') {
      this.subject = 'RE: ' + this.$route.params.message.subject
    } else if (this.$route.params.action === 'draft') {
      const { message } = this.$route.params
      this.subject = message.subject
      this.body = message.body
      this.type = this.messageTypeOptions.find(opt => opt.code === message.type)
      this.part = message.part
      this.attachments = message.attachments
    }

    this.body = this.signature
  },
  mounted () {
    this.$watch('body', () => {
      this.bodyLength = this.$refs['bodyEditor'].getContent({ format: 'text' }).length
      this.validateBody()
      if (this.form.body.error) {
        this.$refs['bodyEditor'].editorInstance.windowManager.alert('Presiahli ste limit 64 000 znakov v správe. Ak potrebujete odoslať takúto správu nahrajte text správy ako prílohu.')
      }
    })
  },
  watch: {
    subject: function () {
      this.validateSubject()
    },
    type: function () {
      this.validateType()
    }
  },
  methods: {
    validateSubject: function () {
      var status = this.form.subject
      var subject = this.subject
      status.valid = status.error = false
      if (subject && subject.length > 1 && subject.length < 251) {
        status.valid = true
      } else {
        status.error = true
      }
    },
    validateType: function () {
      var status = this.form.type
      status.valid = status.error = false
      if (this.type && this.type.code.length > 1) {
        status.valid = true
      } else {
        status.error = true
      }
    },
    validatePart: function () {
      var status = this.form.part
      status.valid = status.error = false
      if (this.part && this.part.id) {
        status.valid = true
      } else {
        status.error = true
      }
    },
    validateBody () {
      var status = this.form.body
      status.valid = status.error = false
      if (this.bodyLength > this.bodyLengthLimit) {
        status.error = true
      } else {
        status.valid = true
      }
    },
    validate: function () {
      this.validateSubject()
      this.validateType()
      this.validateBody()

      let valid = this.form.subject.valid && this.form.type.valid && this.form.body.valid

      if (this.order.divided) {
        this.validatePart()
        valid = valid && this.form.part.valid
      }

      return valid
    },
    goBack: function () {
      window.history.go(-1)
    },
    onError: function (error) {
      let vm = this
      setTimeout(function () {
        vm.$emit('epm:close')
        vm.ErrorAlert({ text: 'Pri odosielaní správy nastala chyba' }, error?.response?.data.error)
      }, 500)
    },
    setMessageButton (state) {
      if (!this.validate()) {
        window.scroll(0, document.getElementById('public-order').scrollHeight)
        return
      }
      this.messageState = state
      if (state === 'sent') {
        this.YesNoAlert({
          text: 'Skutočne chcete odoslať správu?',
          cancelButtonText: 'Zrušiť',
          confirmButtonText: 'Odoslať',
          focusCancel: true
        }).then((result) => {
          if (result.value) {
            this.sendMessage()
          }
        })
      } else {
        this.sendMessage()
      }
    },
    sendMessage () {
      if (this.files.length > 0) {
        this.$emit('epm:new', { title: 'Ukladám prílohy správy', type: 'upload' })
        uploader.init(this.createMessage, this.onError, { order: this.order.id }, this.$http)
        uploader.startUpload(this.files, null)
      } else {
        this.createMessage([])
      }
    },
    async createMessage (attachments) {
      this.$emit('epm:new', { title: this.messageState === 'sent' ? 'Odosielam správu' : 'Ukladám správu', type: 'default' })
      var data = {
        message: {
          type: this.type.code,
          subject: this.subject,
          body: this.body,
          order: this.part ? this.part.id : this.order.id,
          access: 'order',
          to: this.order.user.id,
          toOrgs: [this.order.organization.id],
          attachments: [...attachments, ...this.attachments.map((attachment) => attachment.id)]
        }
      }
      if (this.$route.params.action === 'reply') {
        data.message.parent = this.$route.params.message.id
      }
      if (this.$globals.userOrganization && this.$globals.userOrganization.id) {
        data.message.organization = this.$globals.userOrganization.id
      }
      if (this.$route.params.action === 'draft') {
        data.message.id = this.$route.params.message.id
      }
      try {
        const response = await this.$http.post('/message/set', data)
        if (this.messageState === 'sent') {
          await this.$http.post('/message/send', { message: { id: response.data.id } })
        }
        this.$emit('epm:close')
        this.OkAlert({ text: this.messageState === 'sent' ? 'Správa bola odoslaná' : 'Správa bola uložená' })
        this.$router.push({ name: `${this.routePrefix}user-communication`, params: { orderId: this.order.id } })
      } catch (error) {
        this.onError(error)
      }
    }
  }
}
</script>
