<template>
  <div class="footer">
    <div class="page-container content">
      <div class="header-logo">
        <router-link to="/" title="tenderia" rel="home" class="logo text-white">
          <img src="/tenderia-white.png" alt="" typeof="foaf:Image">
        </router-link>
      </div>
      <div class="links-group">
        <a href="https://www.transparex.sk/about" target="_blank">O nás</a>
        <a href="mailto:podpora@tenderia.sk" target="_blank">Kontakt</a>
        <a href="https://www.transparex.sk/privacy" target="_blank">Ochrana osobných údajov</a>
        <a href="https://www.skolaobstaravania.sk/" target="_blank" title="Škola obstarávania" rel="home" class="logo text-white">
          <img src="/skola-logo.png" alt="" typeof="foaf:Image">
        </a>
        <a href="https://www.transparex.sk/" target="_blank" title="Transparex" rel="home" class="logo text-white">
          <img src="/transparex-logo.png" alt="" typeof="foaf:Image">
        </a>
      </div>
      <div class="social">
        <a class="row m-2" href="https://www.facebook.com/Skolaobstaravania" target="_blank">
          <i class="icon-002-facebook text-white"/>
        </a>
        <a class="row m-2" href="https://www.linkedin.com/showcase/skola-obstaravania/" target="_blank">
          <i class="icon-001-linkedin text-white"/>
        </a>
        <a class="row m-2" href="https://www.youtube.com/@skolaobstaravania" target="_blank">
          <i class="icon-004-youtube text-white"/>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .page-container.content {
    min-height: unset;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    padding: 24px 6px;

    @media (max-width: 900px) {
      grid-template-columns: 1fr;
    }
  }
  a {
    color: inherit;
    text-decoration: none;
    text-align: center;
  }
  .header-logo {
    align-self: center;
    justify-self: start;
    img {
      height: 30px;
    }
  }
  .links-group {
    display: flex;
    align-items: center;
    color: #fff;
    a {
      padding: 6px 0;
      margin: 0 20px;
      @media (max-width: 600px) {
        margin: 0 12px;
      }
    }
  }
  .social {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    i {
      font-size: 24px;
    }
  }
  @media (max-width: 800px) {
    .content {
      grid-template-columns: 1fr;
      align-items: center;
      text-align: center;
      .links-group {
        flex-direction: column;
      }
    }

    .header-logo {
      align-self: center;
      justify-self: center;
    }
    .links-group {
      align-items: center;
    }
    .social {
      margin-top: 8px;
      justify-content: center;
    }
  }
}
</style>
