<template>
  <div class="side-nav">
    <div class="side-nav-inner">
      <div class="left-nav-opener side-nav-toggle">
        <i class="icon-all"></i>
      </div>
      <ul class="side-nav-menu scrollable">
        <li class="nav-item name-space">
          <div v-if="canManageMessages" class="header-messages pr-4 pt-2 overflow-hidden">
            <router-link to="/app/messages" title="Správy"><i class="icon-messages"></i><span v-if="newMessages > 0">{{ newMessages }}</span></router-link>
          </div>
          <router-link to="/app/dashboard">
            <span>{{ $globals.userOrganization.name }}</span>
          </router-link>
        </li>
        <li
          v-if="canManageAsBuyer"
          :class="{open: menuItem.buyer}"
          class="nav-item dropdown"
        >
          <div @click="openMenuItem('buyer')" class="dropdown-switch">
            <span class="title green">Zóna obstarávateľa<i class="arrow icon-dropdown"></i></span>
          </div>
          <v-collapsible>
            <ul v-if="menuItem.buyer" class="dropdown-menu">
              <li><a href="" @click.prevent="openOrderModal({action: 'new'})" class="btn btn-success"><i class="icon-add font-size-14"></i> Vyhlásiť zákazku</a></li>
              <li><router-link to="/app/my-orders/buyer/all" active-class="active"><i class="icon-all"></i>Všetky</router-link></li>
              <li><router-link to="/app/my-orders/buyer/active" active-class="active"><i class="icon-active"></i>Prebiehajúce</router-link></li>
              <li><router-link to="/app/my-orders/buyer/planned" active-class="active"><i class="icon-pending"></i>V príprave</router-link></li>
              <li><router-link to="/app/my-orders/buyer/finished" active-class="active"><i class="icon-ended"></i>Ukončené</router-link></li>
            </ul>
          </v-collapsible>
        </li>
        <li
          v-if="canManageAsContractor"
          :class="{open: menuItem.contractor}"
          class="nav-item dropdown"
        >
          <div @click="openMenuItem('contractor')" class="dropdown-switch">
            <span class="title blue">Zóna uchádzača<i class="arrow icon-dropdown"></i></span>
          </div>
          <v-collapsible>
            <ul v-if="menuItem.contractor" class="dropdown-menu">
              <li><router-link to="/app/orders/all" class="btn btn-tenderia-blue" active-class="active"><i class="icon-add font-size-14"></i> Hľadať príležitosť</router-link></li>
              <li><router-link to="/app/my-orders/contractor/all"><i class="icon-all" active-class="active"></i>Moje zákazky</router-link></li>
              <li><router-link to="/app/my-orders/contractor/active"><i class="icon-active" active-class="active"></i>Prebiehajúce</router-link></li>
              <li><router-link to="/app/my-orders/contractor/finished"><i class="icon-ended" active-class="active"></i>Ukončené</router-link></li>
            </ul>
          </v-collapsible>
        </li>
        <li
          v-if="canManageRequests"
          class="nav-item dropdown"
          :class="{open: menuItem.requests}"
        >
          <div @click="openMenuItem('requests')" class="dropdown-switch">
            <span class="title red">Žiadanky<i class="arrow icon-dropdown"></i></span>
          </div>
          <v-collapsible>
            <ul v-if="menuItem.requests" class="dropdown-menu">
              <li><router-link to="/app/requests/all" active-class="active"><i class="icon-all"></i>Všetky</router-link></li>
              <li><router-link to="/app/requests/approving" active-class="active"><i class="icon-calendar"></i>Čakajúce</router-link></li>
              <li><router-link to="/app/requests/approved" active-class="active"><i class="icon-check"></i>Schválené</router-link></li>
              <li><router-link to="/app/requests/rejected" active-class="active"><i class="icon-ended"></i>Odmietnuté</router-link></li>
            </ul>
          </v-collapsible>
        </li>
        <li
          v-if="canManageRequests"
          class="nav-item dropdown"
          :class="{open: menuItem.myRequests}"
        >
          <div @click="openMenuItem('myRequests')" class="dropdown-switch">
            <span class="title pink">Moje žiadanky<i class="arrow icon-dropdown"></i></span>
          </div>
          <v-collapsible>
            <ul v-if="menuItem.myRequests" class="dropdown-menu">
              <li><router-link to="/app/myRequest/add" active-class="active" class="btn btn-success"><i class="icon-add font-size-14"></i> Vytvoriť žiadanku</router-link></li>
              <li><router-link to="/app/myRequests/all" active-class="active"><i class="icon-all"></i>Všetky</router-link></li>
              <li><router-link to="/app/myRequests/planned" active-class="active"><i class="icon-pending"></i>V príprave</router-link></li>
              <li><router-link to="/app/myRequests/approving" active-class="active"><i class="icon-calendar"></i>Čakajúce</router-link></li>
              <li><router-link to="/app/myRequests/approved" active-class="active"><i class="icon-check"></i>Schválené</router-link></li>
              <li><router-link to="/app/myRequests/rejected" active-class="active"><i class="icon-ended"></i>Odmietnuté</router-link></li>
            </ul>
          </v-collapsible>
        </li>
        <!-- <li
          v-if="canManageInvoice"
          class="nav-item dropdown"
          :class="{open: menuItem.invoices}"
        >
          <div @click="openMenuItem('invoices')" class="dropdown-switch">
            <span class="title orange">Zmluvy<i class="arrow icon-dropdown"></i></span>
          </div>
          <v-collapsible>
            <ul v-if="menuItem.invoices" class="dropdown-menu">
              <li><router-link to="/app/invoice/add" active-class="active" class="btn btn-success"><i class="icon-add font-size-14"></i> Pridať zmluvu</router-link></li>
              <li><router-link to="/app/invoices/all" active-class="active"><i class="icon-all"></i>Všetky</router-link></li>
              <li><router-link to="/app/invoices/active" active-class="active"><i class="icon-active"></i>Aktívne</router-link></li>
              <li><router-link to="/app/invoices/ended" active-class="active"><i class="icon-ended"></i>Ukončené</router-link></li>
            </ul>
          </v-collapsible>
        </li> -->
        <li
          v-if="showMenuSettings"
          class="nav-item dropdown last"
          :class="{open: menuItem.settings}"
        >
          <div @click="openMenuItem('settings')" class="dropdown-switch">
            <span class="title light-blue">Nastavenia<i class="arrow icon-dropdown"></i></span>
          </div>
          <v-collapsible>
            <ul v-if="menuItem.settings" class="dropdown-menu">
              <li v-if="canEditOrganization">
                <router-link to="/app/settings/organizations" active-class="active">
                  <i class="icon-home"></i>
                  <span>Organizácia</span>
                </router-link>
              </li>
              <li v-if="canManageUsers">
                <router-link to="/app/settings/accounts" active-class="active">
                  <i class="icon-user"></i>
                  <span>Používatelia</span>
                </router-link>
              </li>
              <li v-if="canManageSuppliers">
                <router-link to="/app/settings/suppliers" active-class="active">
                  <i class="icon-group"></i>
                  <span>Dodávatelia</span>
                </router-link>
              </li>
              <li v-if="canManageBudgets">
                <router-link to="/app/settings/budgets" active-class="active">
                  <i class="icon-doc-1"></i>
                  <span>Rozpočty</span></router-link>
              </li>
              <li v-if="canManageDepartments">
                <router-link to="/app/settings/departments" active-class="active">
                  <i class="icon-user"></i>
                  <span>Organizačné jednotky</span>
                </router-link>
              </li>
            </ul>
          </v-collapsible>
        </li>
        <li class="nav-item">
          <router-link class="menu-item" to="/app/statistics" active-class="active">
            <span class="title">
              <i class="icon-line-graph mr-2" />Štatistiky
            </span>
          </router-link>
        </li>
      </ul>
      <div class="logout-btn">
        <button @click="logout" class="btn btn-danger">
          Odhlásiť sa <i class="icon-turn-off"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VCollapsible from './ui/Collapsible.vue'
import { permissions } from '@/router/permissions.js'
import STORE from '@/store'
export default {
  name: 'SideNavigation',
  inject: ['eventBus'],
  components: { VCollapsible },
  data () {
    return {
      menuItem: {
        buyer: false,
        contractor: false,
        /* invoices: false, */
        requests: false,
        myRequests: false,
        settings: true
      }
    }
  },
  computed: {
    newMessages () { return STORE.getters.getNewMessages },
    canEditOrganization () { return this.$globals.user.permissions?.includes(permissions.ORGANIZATION_SET) },
    canManageMessages () { return this.$globals.user.permissions?.includes(permissions.MESSAGE_READ) },
    canManageUsers () { return this.$globals.user.permissions?.includes(permissions.ORGANIZATION_USER_LIST) },
    canManageAsBuyer () { return this.$globals.user.permissions?.includes(permissions.ORDER_MENU_BUYER_LIST) },
    canManageAsContractor () { return this.$globals.user.permissions?.includes(permissions.ORDER_MENU_CONTRACTOR_LIST) },
    canManageRequests () { return this.$globals.user.permissions?.includes(permissions.REQUEST_MENU_LIST) },
    canManageBudgets () { return this.$globals.user.permissions?.includes(permissions.BUDGET_MENU_LIST) },
    canManageDepartments () { return this.$globals.user.permissions?.includes(permissions.ORGANIZATION_UNIT_SET) },
    /* canManageInvoice () { return this.$globals.user.permissions.includes('invoice/menu/list/1') true }, */
    canManageSuppliers () { return this.$globals.user.permissions?.includes(permissions.ORGANIZATION_SUPPLIER_SET) },
    showMenuSettings () {
      return this.canEditOrganization ||
      this.canManageUsers ||
      this.canManageSuppliers ||
      this.canManageBudgets ||
      this.canManageDepartments
    }
  },
  methods: {
    openMenuItem (key) {
      Object.keys(this.menuItem).forEach((itemKey) => {
        if (itemKey !== key) {
          this.menuItem[itemKey] = false
        }
      })
      this.menuItem[key] = !this.menuItem[key]
    },
    openOrderModal (config) {
      this.eventBus.$emit('open-order-modal', config)
    },
    async logout () {
      this.ShowLoading()
      try {
        this.eventBus.$emit('clear-message-interval')
        /* let sessionType = localStorage.getItem('sessionType')
        let token = localStorage.getItem('token')
        if (sessionType && sessionType.toLowerCase() === 'eid') {
          localStorage.clear()
          window.location = process.env.VUE_APP_BACKEND_URL + '/auth/eid/logout/start?token=' + token
        } else { */
        await this.$http.post('/auth/logout')
        localStorage.clear()
        this.$router.push({ path: '/' })
        /* } */
        this.CloseLoading()
      } catch (error) {
        this.CloseLoading()
        this.$router.push({ path: '/' })
      } finally {
        localStorage.clear()
        this.eventBus.$emit('clear-message-interval')
        this.$globals.authToken = null
        this.$globals.user = null
        this.$globals.userOrganization = null
      }
    }
  },
  mounted () {
    function sideNavToggle () {
      $('.side-nav-toggle').on('click', function (e) {
        $('#app').toggleClass('side-nav-open')
        e.preventDefault()
      })
    }

    function init () {
      sideNavToggle()
    }
    init()
  }
}
</script>
