<script>

import CountrySelect from '@/components/forms/CountrySelect.vue'
import uploader from '@/components/utils/uploader.js'
import validator from '@/components/validator.js'
import BaseOffer from '@/components/order/common/components/BaseOffer.vue'
import Swal from 'sweetalert2'

export default {
  name: 'BaseUserOfferForm',
  extends: BaseOffer,
  components: { CountrySelect },
  inject: ['eventBus'],
  data () {
    return {
      text: {
        t1: 'Časový limit na predkladanie ponúk vypršal.',
        t2: 'Pri ukladaní vašej ponuky nastala chyba.',
        t3: 'Ponuka bola uložená pre odoslanie zvoľte možnosť "Odoslať ponuku"',
        t4: 'V ponuke sú chyby a preto nie je možné ju uložiť alebo odoslať. Musíte správne vyplniť všetky povinné polia.',
        t5: 'Skutočne chcete odoslať ponuku?',
        t6: 'Vaša ponuka bola odoslaná do systému TENDERIA',
        t7: 'Ukladám ponuku',
        t8: 'Odosielam ponuku'
      },
      finishAction: null,
      offer: {
        applicantDetails: {
          businessName: '',
          address: '',
          ico: '',
          vatPayer: '',
          country: '',
          contactPerson: '',
          phone: '',
          fax: '',
          email: ''
        }
      },
      files: [],
      files2: [],
      fileSizeMax: 2147483648,
      messages: [],
      messages2: [],
      items: [],
      id: null,
      form: {
        businessName: {
          error: false,
          valid: false
        },
        address: {
          error: false,
          valid: false
        },
        country: {
          error: false,
          valid: false
        },
        contactPerson: {
          error: false,
          valid: false
        },
        phone: {
          error: false,
          valid: false
        },
        fax: {
          error: false,
          valid: false
        },
        email: {
          error: false,
          valid: false
        },
        vatPayer: {
          error: false,
          valid: false
        }
      }
    }
  },
  computed: {
    addressTextRemaining () {
      return 256 - this.offer.applicantDetails.address.length
    },
    vatPayer () {
      return this.offer.applicantDetails.vatPayer === 'yes'
    },
    vatPayerfilled () {
      return !!this.offer.applicantDetails.vatPayer
    },
    bidsLimitTo () {
      return this.order.bidsLimitTo ? new Date(this.order.bidsLimitTo) : false
    },
    canSaveOffer () {
      return this.bidsLimitTo && this.serverTime < this.bidsLimitTo && this.authLevelComplied
    },
    canSendOffer () {
      return this.bidsLimitTo && this.serverTime < this.bidsLimitTo && this.authLevelComplied
    }
  },
  watch: {
    'offer.applicantDetails.businessName': function () {
      this.validateBusinessName()
    },
    'offer.applicantDetails.address': function () {
      this.validateAddress()
    },
    'offer.applicantDetails.country': function () {
      this.validateCountry()
    },
    'offer.applicantDetails.contactPerson': function () {
      this.validateContactPerson()
    },
    'offer.applicantDetails.phone': function () {
      this.validatePhone()
    },
    'offer.applicantDetails.fax': function () {
      this.validateFax()
    },
    'offer.applicantDetails.email': function () {
      this.validateEmail()
    },
    'offer.applicantDetails.vatPayer': function () {
      this.validateVatPayer()
      this.validateCriteria()
    }
  },
  methods: {
    taxInclusion (criterion) {
      if (criterion.type === 'price') {
        return criterion.taxInclusion ? 's DPH' : 'bez DPH'
      } else {
        return ''
      }
    },
    validateCriteria () {
      return this.criteria.reduce((accumulator, criterion) => {
        this.validateCriterion(criterion)
        return accumulator && criterion.valid
      }, true)
    },
    validateVatPayer () {
      var status = this.form.vatPayer
      status.error = status.valid = false
      let value = this.offer.applicantDetails.vatPayer
      if (value === 'yes' || value === 'no') {
        status.valid = true
      } else {
        status.error = true
      }
    },
    validateAddress () {
      var status = this.form.address
      status.error = status.valid = false
      var length = this.offer.applicantDetails.address.length
      if (length > 0 && length <= 256) {
        status.valid = true
      } else {
        status.error = true
      }
    },
    validateCountry () {
      var status = this.form.country
      status.error = status.valid = false
      if (this.offer.applicantDetails.country?.length > 0) {
        status.valid = true
      } else {
        status.error = true
      }
    },
    validateContactPerson () {
      var status = this.form.contactPerson
      status.error = status.valid = false
      var length = this.offer.applicantDetails.contactPerson.length
      if (length > 0 && length <= 256) {
        status.valid = true
      } else {
        status.error = true
      }
    },
    validatePhone () {
      var status = this.form.phone
      status.error = status.valid = false
      if (validator.validatePhone(this.offer.applicantDetails.phone)) {
        status.valid = true
      } else {
        status.error = true
      }
    },
    validateFax () {
      var status = this.form.fax
      status.error = status.valid = false
      if (!this.offer.applicantDetails.fax) {
        status.valid = true
        return
      }
      if (validator.validatePhone(this.offer.applicantDetails.fax)) {
        status.valid = true
      } else {
        status.error = true
      }
    },
    validateEmail () {
      var status = this.form.email
      status.error = status.valid = false
      if (validator.validateEmail(this.offer.applicantDetails.email)) {
        status.valid = true
      } else {
        status.error = true
      }
    },
    validateCriterion (criterion) {
      let minimalValue = criterion.minimalValue
      let maximalValue = criterion.maximalValue === Number.MIN_SAFE_INTEGER ? Number.MAX_SAFE_INTEGER : criterion.maximalValue
      let computedValue = parseFloat(criterion.userInput.toString().replace(/\s+/g, '').replace(',', '.'))
      criterion.error = criterion.valid = false
      criterion.value = ''
      if (isNaN(computedValue)) {
        criterion.error = true
        return
      }
      if (!isNaN(minimalValue) && minimalValue > computedValue) {
        criterion.error = true
        return
      }
      if (!isNaN(maximalValue) && maximalValue < computedValue) {
        criterion.error = true
        return
      }
      criterion.valid = true
      criterion.userInput = this.formatNumber(computedValue, criterion.decimalCount)
      criterion.value = computedValue
    },
    setFiles (event) {
      this.messages = []
      for (var i = 0; i < event.target.files.length; i++) {
        if (event.target.files[i].size <= this.fileSizeMax) {
          this.files.push(event.target.files[i])
        } else {
          this.messages.push(`Súbor ${event.target.files[i].name} prekračuje maximálnu veľkosť 2GB.`)
        }
      }
    },
    setFiles2 (event) {
      this.messages2 = []
      for (var i = 0; i < event.target.files.length; i++) {
        if (event.target.files[i].size <= this.fileSizeMax) {
          this.files2.push(event.target.files[i])
        } else {
          this.messages2.push(`Súbor ${event.target.files[i].name} prekračuje maximálnu veľkosť 2GB.`)
        }
      }
    },
    removeFile (index) {
      this.files.splice(index, 1)
    },
    removeFile2 (index) {
      this.files2.splice(index, 1)
    },
    removeSavedFileModal (index, fileList) {
      this.YesNoAlert({
        text: `Skutočne chcete zmazať súbor ${fileList[index].name} ?`,
        focusCancel: true,
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Zmazať'
      }).then((result) => {
        if (result.value) {
          this.removeSavedFile(index, fileList)
        }
      })
    },
    removeSavedFile (index, fileList) {
      var data = {
        offer: {
          id: this.offer.id
        },
        attachment: {
          id: fileList[index].id
        }
      }
      this.$http.post('/offer/attachment/remove', data).then(function () {
        fileList.splice(index, 1)
      })
    },
    onError (error) {
      this.$emit('epm:close')
      let vm = this
      if (error.response && error?.response?.data.error.code === 410) {
        setTimeout(function () {
          vm.ErrorAlert({ text: vm.text.t1 })
        }, 500)
      } else {
        setTimeout(function () {
          vm.ErrorAlert({ text: vm.text.t2 })
        }, 500)
      }
    },
    finish () {
      let vm = this
      setTimeout(function () {
        vm.$emit('epm:close')
        if (vm.finishAction && typeof vm.finishAction === 'function') {
          vm.finishAction()
        } else {
          vm.OkAlert({ text: vm.text.t3 }).then(() => {
            window.history.go(-1)
          })
        }
      }, 500)
    },
    sendOffer () {
      let proposals = this.criteria.reduce((text, criterion) => `${text}<tr><th class="text-right">${criterion.name}</th><td class="text-left pdd-left-10">${criterion.userInput} ${criterion.unit}</td></tr>`, '')

      Swal.fire({
        title: this.text.t5,
        type: 'warning',
        html: `<table style="margin: auto"><tbody>${proposals}</tbody></table>`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Odoslať',
        cancelButtonText: 'Zrušiť',
        confirmButtonColor: '#1FCB7C',
        cancelButtonColor: '#EB5757',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          this.sendOfferData()
        }
      })
    },
    sendOfferData () {
      this.$emit('epm:new', { title: this.text.t8, type: 'default' })
      var data = {
        offer: {
          order: this.order.id,
          state: 'sent',
          organization: this.$globals.userOrganization.id,
          id: this.offer.id
        }
      }
      let vm = this
      this.$http.post('/offer/set', data).then(function () {
        setTimeout(function () {
          vm.$emit('epm:close')
          vm.eventBus.$emit('order-updated')
          vm.OkAlert({ text: vm.text.t6 }).then(() => {
            window.history.go(-1)
          })
        }, 500)
      }).catch(function (error) {
        vm.onError(error)
      })
    },
    addAttachemnts (attachments) {
      let data = {
        offer: {
          id: this.id
        },
        stageLevel: 1,
        attachment: {
          id: attachments
        }
      }
      let vm = this
      this.$http.post('/offer/attachment/add', data).then(function () {
        if (vm.files2.length > 0) {
          uploader.init(vm.addAttachemnts2, vm.onError, { access: 'offer', offer: vm.id, order: vm.order.id }, vm.$http, vm.$globals.authToken)
          uploader.startUpload(vm.files2, {
            offset: vm.files.length,
            totalCount: vm.files.length + vm.files2.length
          })
        } else {
          vm.finish()
        }
      }).catch(function (error) {
        vm.onError(error)
      })
    },
    addAttachemnts2 (attachments) {
      let data = {
        offer: {
          id: this.id
        },
        stageLevel: 2,
        attachment: {
          id: attachments
        }
      }
      let vm = this
      this.$http.post('/offer/attachment/add', data).then(function () {
        vm.finish()
      }).catch(function (error) {
        vm.onError(error)
      })
    },
    saveOffer (finishAction) {
      if (!this.validateOffer()) {
        this.ErrorAlert({ text: this.text.t4 })
        return
      }

      this.finishAction = finishAction
      this.$emit('epm:new', { title: this.text.t7, type: 'default' })
      let data = {
        offer: {
          order: this.order.id,
          organization: this.$globals.userOrganization.id,
          applicantDetails: this.offer.applicantDetails,
          proposals: [],
          items: this.items
        }
      }
      for (let i = 0; i < this.criteria.length; i++) {
        data.offer.proposals.push({ criterion: this.criteria[i]._id, value: this.criteria[i].value })
      }
      let vm = this
      this.$http.post('/offer/set', data).then(function (response) {
        vm.id = response.data.id
        if (vm.files.length > 0) {
          vm.$emit('epm:new', { title: 'Ukladám prílohy ponuky', type: 'upload' })
          uploader.init(vm.addAttachemnts, vm.onError, { access: 'offer', offer: response.data.id, order: vm.order.id }, vm.$http, vm.$globals.authToken)
          uploader.startUpload(vm.files, {
            offset: 0,
            totalCount: vm.files.length + vm.files2.length
          })
        }
        if (vm.files.length === 0 && vm.files2.length > 0) {
          vm.$emit('epm:new', { title: 'Ukladám prílohy ponuky', type: 'upload' })
          uploader.init(vm.addAttachemnts2, vm.onError, { access: 'offer', offer: response.data.id, order: vm.order.id }, vm.$http, vm.$globals.authToken)
          uploader.startUpload(vm.files2, null)
        }
        if (vm.files.length === 0 && vm.files2.length === 0) {
          vm.finish()
        }
      }).catch(function (error) {
        vm.onError(error)
      })
    },
    setProposals () {
      for (let proposal of this.offer.proposals) {
        for (let criterion of this.criteria) {
          if (criterion._id === proposal.criterion && proposal.value) {
            criterion.userInput = this.formatNumber(proposal.value, criterion.decimalCount)
            this.validateCriterion(criterion)
          }
        }
      }
    },
    prepareCriteria () {
      for (let criterion of this.order.criteria) {
        criterion.unit = criterion.type === 'price' ? 'EUR' : criterion.unit
        this.criteria.push(Object.assign({}, criterion, { value: '', userInput: '', error: false, valid: false }))
      }
      this.priceCriterion = this.findPriceCriterion()
      if (this.priceCriterion) {
        this.decimalCount = this.priceCriterion.decimalCount
      }
    }
  }
}
</script>
